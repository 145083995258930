<template>
    <div class="formBox" v-loading="loading" >
      <div class="loading_po"  v-loading="loading" element-loading-text="请稍后..."    element-loading-spinner="el-icon-loading">
  
      </div>
      <div style="display: flex; align-items: center">
        <h4 style="color: #146aff; padding: 0px 20px 0px 30px">基本信息</h4>
  
        <!-- <el-checkbox v-model="checked" @change="clcikChecked">通用资讯</el-checkbox> -->
      </div>
    <div style="background: #fff;">
        <el-form  ref="form" :model="form" label-width="120px" :rules="updatePwdRules">
        <el-form-item label="资讯类型" prop="informationType">
          <div style="margin-top: 10px">
            <el-radio-group v-model="form.informationType">
              <el-radio :label="1">应用场景</el-radio>
              <el-radio :label="2">数智技术</el-radio>
              <el-radio :label="3">行业动态</el-radio>
              <el-radio :label="4">数智案例</el-radio>
              <el-radio :label="5">数智产品</el-radio>
              <el-radio :label="6">服务商</el-radio>
              <el-radio :label="7">政策解读</el-radio>
              <el-radio :label="8">组织人才</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item v-if="form.informationType == 5" label="产品类型" prop="informationType">
          <div style="margin-top: 10px">
            <el-radio-group v-model="form.productType">
              <el-radio label="软件类型">软件类型</el-radio>
              <el-radio label="硬件类型">硬件类型</el-radio>
              <el-radio label="解决方案">解决方案</el-radio>
              <el-radio label="数据服务">数据服务</el-radio>
              <el-radio label="产业服务">产业服务</el-radio>
              <el-radio label="其他产品">其他产品</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
        <el-form-item label="资讯名称" prop="informationName">
          <el-input v-model="form.informationName" placeholder="请输入资讯名称"></el-input>
        </el-form-item>
        <el-form-item label="一句话介绍:" prop="informationBrief">
          <div class="txtree">
            <el-input
              v-model="form.informationBrief"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              placeholder="请输入一句话简介"
              maxlength="50"
              show-word-limit
            />
          </div>
        </el-form-item>
        <el-form-item label="封面图" prop="imageList">
          <div class="cover">
            <div class="img_box">
              <uploadCover
                :fileList="form.imageList"
                @uploadOneImg="uploadOneImg"
                @removeImg="removeImg"
              ></uploadCover>
              <span style="color: #146aff">建议图片整体尺寸为442*226 px大小不超过12MB</span>
            </div>
            <div v-if="imageUrlList.length > 0">缩略图</div>
            <div v-if="imageUrlList.length > 0" class="thumbnail">
              <div class="thumbnail_box">
                <img :src="imageUrlList[0]" alt="" />
                <div>图片尺寸：442*226 px</div>
                <div>图片大小：4MB</div>
                <div>展示位置：首页</div>
              </div>
              <div class="thumbnail_box">
                <img :src="imageUrlList[0]" alt="" />
                <div>图片尺寸：442*226 px</div>
                <div>图片大小：5MB</div>
                <div>展示位置：文章详情页</div>
              </div>
              <div class="thumbnail_box">
                <img :src="imageUrlList[0]" alt="" />
                <div>图片尺寸：442*226 px</div>
                <div>图片大小：10MB</div>
                <div>展示位置：首页banner区域</div>
              </div>
            </div>
          </div>
        </el-form-item>
        <div v-if="form.informationType == 4">
          <el-form-item label="视频" prop="videoList">
            <div class="cover">
              <div class="img_box" v-if="form.videoResults">
                <uploadVideo
                  :videos="form.videoResults[0].url"
                  @uploadOneImg="uploadOneImgvideo"
                  @removeImg="removeImgvideo"
                ></uploadVideo>
                <span style="color: #146aff">建议视频整体尺寸为442*226 px，大小不超过20MB</span>
              </div>
              <div class="img_box" v-else>
                <uploadVideo
                  @uploadOneImg="uploadOneImgvideo"
                  @removeImg="removeImgvideo"
                ></uploadVideo>
                <span style="color: #146aff">建议视频整体尺寸为442*226 px，大小不超过20MB</span>
              </div>
              <div v-if="form.videoResults">缩略图</div>
              <div v-if="form.videoResults" class="thumbnail">
                <div class="thumbnail_box">
                  <img :src="form.videoResults[0].coverImage" alt="" />
                  <div>视频尺寸：442*226 px</div>
                  <div>图片大小：4MB</div>
                  <div>展示位置：首页</div>
                </div>
                <div class="thumbnail_box">
                  <img :src="form.videoResults[0].coverImage" alt="" />
                  <div>视频尺寸：442*226 px</div>
                  <div>图片大小：5MB</div>
                  <div>展示位置：文章详情页</div>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="视频播放次数">
            <div class="number_top">
              <span>{{ form.loopCount }}</span>
            </div>
            <div class="number_loop">
              <el-input-number v-model="form.loopCount" :min="1" :max="1000000"></el-input-number>
              <el-switch
                style="margin-left: 20px"
                v-model="form.loopStatus"
                active-color="#3562ff"
                active-value="1"
                inactive-value="0"
              >
              </el-switch>
              <div style="position: relative; top: 10px; left: 10px" v-if="form.loopStatus == 1">
                已启用
              </div>
            </div>
            <span style="color: #bebebe">此播放次数为虚假数据，如启用，需展示在前台</span>
          </el-form-item>
        </div>
        <div v-if="form.informationType == 6">
          <el-form-item label="关联企业" prop="">
            <div style="display: flex; align-items: flex-end">
              <el-autocomplete
                v-model="form.informationCompanies[0].companyFullName"
                :fetch-suggestions="
                  (res, cb) => {
                    return querySearchAsyncaptname(res, cb)
                  }
                "
                placeholder="请输入关联企业内容名称关键词"
                @select="
                  (res) => {
                    return handleSelectcaptname(res)
                  }
                "
              >
                <template slot-scope="{ item }">
                  <div class="name">
                    <img
                      :src="item.companyLogo"
                      style="border-radius: 28px"
                      width="28px"
                      height="28px"
                      alt=""
                    />
                    <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                      item.companyFullName
                    }}</span>
                  </div>
                </template>
              </el-autocomplete>
              <el-tag v-if="form.informationCompanies[0].companyFullName" style="margin-left:20px ;" type="info">{{ form.informationCompanies[0].companyFullName}}</el-tag>
              <el-checkbox style="margin-left: 20px;position: relative;top: 5px;" v-model="checked1" @change="checkedchaneg"
                >可提供本地服务</el-checkbox
              >
            </div>
          </el-form-item>
          <el-form-item label="企业简介:" prop="enterpriseIntro">
            <div class="txtree">
              <el-input
                v-model="form.enterpriseIntro"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 6 }"
                placeholder="请输入一句话简介"
                maxlength="200"
                show-word-limit
              />
            </div>
          </el-form-item>
          <el-form-item label="企业附件:" prop="">
            <div
              class="name_form"
              style="width: 700px; display: flex; justify-content: space-between"
            >
              <el-input v-model="form.companyFile" placeholder="请上传企业附件" />
              <el-upload
                action
                class="upload-demo"
                ref="upload"
                :show-file-list="false"
                :before-upload="beforeUpload"
                :http-request="upload"
              >
                <el-button
                  style="position: relative; left: -160px"
                  slot="trigger"
                  size="mini"
                  type="primary"
                  plain
                  >上传附件</el-button
                >
              </el-upload>
            </div>
          </el-form-item>
        </div>
        <div v-if="form.informationType == 5">
          <el-form-item label="产品功能:" prop="informationProducts">
            <div class="general_inputs">
              <div class="input_flex" v-for="(item, index) in form.informationProducts" :key="index">
                <div style="display: flex; align-items: center">
                  <img-big-upload
                    :imageShow.sync="item.productImage"
                    :addTitleShow="false"
                    @removeImg="
                      (res) => {
                        return removeImgDianprod(res, index)
                      }
                    "
                    @uploadOneImgShow="
                      (res) => {
                        return uploadOneImgShowDianprod(res, index)
                      }
                    "
                  />
                  <div class="textarea" style="margin-left: 10px">
                    <el-input
                      v-model="item.productFunctionDescribe"
                      type="textarea"
                      placeholder="请输入功能描述"
                      maxlength="10"
                      show-word-limit
                    />
                  </div>
                </div>
                <div style="display: flex; font-size: 24px; margin-left: 20px">
                  <span v-if="index == 0" style="color: #4e93fb" @click="addDescribe"
                    ><i class="el-icon-circle-plus"></i
                  ></span>
                  <span v-if="index > 0" style="color: #ff7575" @click="delDescribe(index)"
                    ><i class="el-icon-delete"></i
                  ></span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="产品简介:" prop="productBrief">
            <div class="txtree">
              <el-input
                v-model="form.productBrief"
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 6 }"
                placeholder="请输入一句话简介"
                maxlength="150"
                show-word-limit
              />
            </div>
          </el-form-item>
          <el-form-item label="产品附件:" prop="productFile">
            <div
              class="name_form"
              style="width: 700px; display: flex; justify-content: space-between"
            >
              <el-input v-model="form.productFile" placeholder="请上传企业附件" />
              <el-upload
                action
                class="upload-demo"
                ref="upload"
                :show-file-list="false"
                :before-upload="beforeUploadprod"
                :http-request="uploadprod"
              >
                <el-button
                  style="position: relative; left: -160px"
                  slot="trigger"
                  size="mini"
                  type="primary"
                  plain
                  >上传附件</el-button
                >
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item label="应用价值:" prop="">
            <div class="general_inputs">
              <div
                class="input_flex"
                v-for="(item, index) in form.informationProductValues"
                :key="index"
              >
                <div>
                 <div class="valueType_flex">
                  <div style="margin-right:20px;">价值类型</div>
                  <el-radio-group v-model="item.valueType">
                    <el-radio label="提升">提升</el-radio>
                    <el-radio label="降低">降低</el-radio>
                  </el-radio-group>
                 </div>
                  <div class="textarea">
                    <el-input
                      v-model="item.valueTitle"
                      type="textarea"
                      placeholder="请输入应用价值标题"
                      maxlength="10"
                      show-word-limit
                    />
                  </div>
                  <div style="display: flex; align-items: center; padding-top: 20px">
                    <div class="percentage">
                      <el-input placeholder="" v-model="item.input1">
                        <template slot="append">%</template>
                      </el-input>
                    </div>
                    <div style="padding: 0px 10px">至</div>
                    <div class="percentage">
                      <el-input placeholder="" v-model="item.input2">
                        <template slot="append">%</template>
                      </el-input>
                    </div>
                  </div>
                </div>
  
                <div style="display: flex; font-size: 24px; margin-left: 20px">
                  <span v-if="index == 0" style="color: #4e93fb" @click="addvalueTitle"
                    ><i class="el-icon-circle-plus"></i
                  ></span>
                  <span v-if="index > 0" style="color: #ff7575" @click="delvalueTitle(index)"
                    ><i class="el-icon-delete"></i
                  ></span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="产品热度">
            <div class="number_loop">
              <el-input-number v-model="form.productHeat" :min="1" :max="1000000"></el-input-number>
              <el-switch
                style="margin-left: 20px"
                v-model="form.productHeatStatus"
                active-color="#3562ff"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
              <div style="position: relative; top: 10px; left: 10px" v-if="form.loopStatus == 1">
                已启用
              </div>
            </div>
            <span style="color: #bebebe">此设置为虚假数据，如启用，需展示在前台</span>
          </el-form-item>
        </div>
  
        <div style="border-top: 20px solid #f6f7fb">
          <h4 style="color: #146aff; padding-left: 30px">发布机构信息</h4>
          <el-form-item label="发布机构/人" prop="issueName">
            <el-autocomplete
              v-model="form.issueName"
              :fetch-suggestions="querySearchAsyncapt"
              placeholder="请搜索选择发布机构"
              @select="handleSelectcapt"
            >
              <template slot-scope="{ item }">
                <div class="name">
                  <img
                    :src="item.issueLogo"
                    style="border-radius: 28px"
                    width="28px"
                    height="28px"
                    alt=""
                  />
  
                  <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                    item.issueName
                  }}</span>
                </div>
              </template>
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="logo" prop="logo">
            <uploadLogo :imageShow="form.logo" @uploadOneImgShow="uploadOneImgShow"></uploadLogo>
          </el-form-item>
          <el-form-item label="来源">
            <el-input v-model="form.resource" placeholder="请输入来源"></el-input>
          </el-form-item>
        </div>
        <div
          style="border-top: 20px solid #f6f7fb; padding-top: 30px"
          v-if="form.informationType >= 4"
        >
          <h4 style="color: #146aff; padding-left: 30px">关联信息</h4>
  
          <el-form-item v-if="form.informationType != 6" label="关联企业" prop="informationCompanies">
            <div
              class="input_flex"
              style="display: flex"
              v-for="(item, index) in form.informationCompanies"
              :key="index"
            >
              <div>
                <div class="name_form" style="padding-bottom: 10px">
                  <el-autocomplete
                    v-model="item.companyFullName"
                    :fetch-suggestions="
                      (res, cb) => {
                        return querySearchAsyncapts3(res, cb, index)
                      }
                    "
                    placeholder="请输入关联企业内容名称关键词"
                    @select="
                      (res) => {
                        return handleSelectcapts3(res, index)
                      }
                    "
                  >
                    <template slot-scope="{ item }">
                      <div class="name">
                        <img
                          :src="item.companyLogo"
                          style="border-radius: 28px"
                          width="28px"
                          height="28px"
                          alt=""
                        />
                        <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                          item.companyFullName
                        }}</span>
                      </div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
              <div style="display: flex; font-size: 24px">
                <span v-if="index == 0" style="color: #4e93fb" @click="addenterprise"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delenterprise(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
              <el-tag v-if="item.companyFullName" style="margin-left:20px ;" type="info">{{ item.companyFullName}}</el-tag>
            </div>
  
          </el-form-item>
          <el-form-item v-if="form.informationType != 4" label="关联案例" prop="facilitatorResults">
            <div
              class="input_flex"
              style="display: flex"
              v-for="(item, index) in form.facilitatorResults"
              :key="index"
            >
              <div>
                <div class="name_form" style="padding-bottom: 10px">
                  <el-autocomplete
                    v-model="item.informationName"
                    :fetch-suggestions="
                      (res, cb) => {
                        return querySearchAsyncapts(res, cb, index)
                      }
                    "
                    placeholder="输入关联案例内容名称关键词"
                    @select="
                      (res) => {
                        return handleSelectcapts(res, index)
                      }
                    "
                  >
                    <template slot-scope="{ item }">
                      <div class="name">
                        <img
                          :src="item.companyLogo"
                          style="border-radius: 28px"
                          width="28px"
                          height="28px"
                          alt=""
                        />
                        <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                          item.informationName
                        }}</span>
                      </div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
              <div style="display: flex; font-size: 24px">
                <span v-if="index == 0" style="color: #4e93fb" @click="addareas"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delareas(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </el-form-item>
          <el-form-item v-if="form.informationType != 5" label="关联产品" prop="productResults">
            <div
              class="input_flex"
              style="display: flex"
              v-for="(item, index) in form.productResults"
              :key="index"
            >
              <div>
                <div class="name_form" style="padding-bottom: 10px">
                  <el-autocomplete
                    v-model="item.informationName"
                    :fetch-suggestions="
                      (res, cb) => {
                        return querySearchAsyncapts1(res, cb, index)
                      }
                    "
                    placeholder="请输入产品资讯关键词"
                    @select="
                      (res) => {
                        return handleSelectcapts1(res, index)
                      }
                    "
                  >
                    <template slot-scope="{ item }">
                      <div class="name">
                        <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                          item.informationName
                        }}</span>
                      </div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
              <div style="display: flex; font-size: 24px">
                <span v-if="index == 0" style="color: #4e93fb" @click="addareasprod"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delareasprod(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="关联专家" prop="">
            <div
              class="input_flex"
              style="display: flex"
              v-for="(item, index) in form.specialistResults"
              :key="index"
            >
              <div>
                <div class="name_form" style="padding-bottom: 10px">
                  <el-autocomplete
                    v-model="item.specialistName"
                    :fetch-suggestions="
                      (res, cb) => {
                        return querySearchAsyncapts2(res, cb, index)
                      }
                    "
                    placeholder="请输入关联专家关键词"
                    @select="
                      (res) => {
                        return handleSelectcapts2(res, index)
                      }
                    "
                  >
                    <template slot-scope="{ item }">
                      <div class="name">
                        <span class="addr" style="position: relative; top: -8px; left: 5px">{{
                          item.name
                        }}</span>
                      </div>
                    </template>
                  </el-autocomplete>
                </div>
              </div>
              <div style="display: flex; font-size: 24px">
                <span v-if="index == 0" style="color: #4e93fb" @click="addareaspeci"
                  ><i class="el-icon-circle-plus"></i
                ></span>
                <span v-if="index > 0" style="color: #ff7575" @click="delareaspeci(index)"
                  ><i class="el-icon-delete"></i
                ></span>
              </div>
            </div>
          </el-form-item>
        </div>
        <div v-if="form.informationType < 4">
          <el-form-item label="发布时间">
            <el-date-picker
              v-model="form.issueTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              prefix-icon="el-icon-date"
              class="picker"
            >
            </el-date-picker>
          </el-form-item>
  
          <el-form-item label="资讯描述" prop="informationIntro">
            <div class="editBarBox">
              <div class="editBar">
                <Editorbar v-model="form.informationIntro" :isClear="isClear" />
                <!-- <tinymceEditor
                ref="editor"
                v-model="form.informationIntro"
                @input="inputVla"
              ></tinymceEditor> -->
              </div>
            </div>
          </el-form-item>
        </div>
  
        <div style="border-top: 20px solid #f6f7fb">
          <h4 class="h4_share">小程序分享链接信息</h4>
          <el-form-item label="分享链接标题:" prop="">
            <el-input v-model="form.shareTitle" placeholder="请输入分享链接标题" />
          </el-form-item>
          <el-form-item label="分享链接图片:" prop="">
            <div class="pictures_box_left">
              <img-big-upload
                :imageShow.sync="form.shareImage"
                :addTitleShow="false"
                @removeImg="removeImgDians"
                @uploadOneImgShow="uploadOneImgShowDians"
              />
            </div>
            <div class="img_tips">
              <span>(建议整体尺寸为630*510px，图片格式应为jpg、jpeg、png，图片应小于12MB)</span>
            </div>
          </el-form-item>
        </div>
      </el-form>
      <div class="editInformation" v-if="activeId">
        <div class="item">编辑人：{{ form.createId }}</div>
        <div class="item">编辑时间：{{ form.createTime }}</div>
        <div class="item">发送人：{{ form.updateId }}</div>
        <div class="item">发送时间：{{ form.updateTime }}</div>
      </div>
      <div class="button_box">
        <!-- <div class="send button" @click="clickSend">发送</div>
        <div class="preview button" @click="clickPreview">预览</div> -->
        <div class="save button" @click="clickSave">保存</div>
        <div class="cancel button" @click="clickCancel">取消</div>
      </div>
    </div>
     
    </div>
  </template>
  <script>
  import {
    selectById,
    update,
    save,
    getIssueName,
    getInformationAssociationVo,
    getSpecialistAssociationVo,
    getCompanyList
  } from '@/api/content.js'
  import { apiUpload } from '@/api/demand'
  import uploadLogo from './uploadLogo.vue'
  import uploadCover from './uploadCoverOne.vue'
  import uploadVideo from './uploadVideo.vue'
  import Editorbar from './Editorbar.vue'
 
  export default {
    components: { uploadLogo, uploadCover, Editorbar, uploadVideo },
    name:'addcontent',
    computed: {
    activeId() {
      return this.$route.query.id
    }
  },
    data() {
      return {
        checked1: false, //本地服务
        loading:false,//loadding
        form: {
            informationChannel:2,//默认传2 2->合作伙伴
            schemaName:'',//智参中心schema值
          serviceStatus: null,
          companyFile: null, //企业附件
          productFile: null, //产品附件
          loop: 1, //播放次数
          createId: null, // 创建者ID
          createTime: '', // 创建时间
          issueNameOptions: [],
          facilitatorResults: [
            {
              id: null,
              informationName: null
            }
          ], // 服务商名称
          productResults: [
            {
              id: null,
              informationName: null
            }
          ], //产品
          specialistResults: [
            {
              id: null,
  
              specialistName: null
            }
          ], //专家
          informationCompanies: [
            {
              id: null,
              companyFullName: null
            }
          ], //关联企业
          informationProducts: [
            {
              id: null,
              informationId: this.activeId,
              productFunctionDescribe: null,
              productImage: ''
            }
          ],
          //应用价值
          informationProductValues: [
            {
              id: null,
              informationId: this.activeId,
              value: [],
              valueTitle: null
            }
          ],
          videoResults: null,
          videos: null,
          generalVersion: '1', //默认个性版
          id: null, //	主键
          imageList: null, // 资讯图片，多张
          informationIntro: null, //资讯描述
          informationName: null, //资讯名称
          informationType: 1, // 1应用场景，2数智技术，3竞情，4案例，5产品，6服务商
          issueId: null, // 	发布企业/人
          issueName: null,
          issueTime: null, // 	发布时间
          informationBrief: '',
          logo: null, // 	资讯logo
          resource: null, // 来源
          updateId: null, // 	更新者ID
          updateTime: null, // 更新时间
          videoList: [], // 		资讯视频，多个
          shareImage: '',
          shareTitle: null
        },
        parteninfo: JSON.parse(sessionStorage.getItem('typeMode')),

        checked: false, //通用版
        // logo图片
        imageOneShow: '',
        imageUrlList: [],
        videoList: [],
        isClear: false,
        updatePwdRules: {
          informationName: [{ required: true, message: '请输入资讯名称', trigger: 'blur' }],
          informationBrief: [{ required: true, message: '请输入一句话简介', trigger: 'blur' }],
          issueName: [{ required: true, message: '请输入发布机构/人' }],
          informationType: [{ required: true, message: '请输入资讯类型', trigger: 'change' }],
          resource: [{ required: true, message: '请输入来源', trigger: 'blur' }],
          informationIntro: [{ required: true, message: '请输入资讯描述', trigger: 'change' }],
          imageList: [{ required: true, message: '请上传封面图', trigger: 'blur' }],
          logo: [{ required: true, message: '请上传logo', trigger: 'blur' }],
          informationProducts: [{ required: true, message: '请设置产品功能', trigger: 'blur' }],
          productBrief: [{ required: true, message: '请设置输入产品简介', trigger: 'blur' }]
  
          // productFile : [{ required: true, message: '请上传产品附件', trigger: 'blur' }],
  
          // informationCompanies : [{ required: true, message: '请设置关联企业', trigger: 'blur' }],
          // facilitatorResults : [{ required: true, message: '请设置关联案例', trigger: 'blur' }],
          // productResults : [{ required: true, message: '请设置关联产品', trigger: 'blur' }],
        },
        issueNameOption: [],
        facilitatorOption: [],
        specialist: [], //服务商类别资讯
        facilist: [], //产品类别资讯
        specilist: [] //专家所有
      }
    },
   
    methods: {
      // 富文本编辑器的内容
      inputVla(val) {
        // console.log(val)
      },
      async getSelectById() {
        const { data: res } = await selectById({ id: this.activeId })
        // console.log(res)
        if (res.resultCode == 200) {
          // console.log(res)
          this.form = res.data
          this.imageUrlList = this.form.imageList
          if (this.form.serviceStatus) {
            if (this.form.serviceStatus == 1) {
              this.checked1 = true
            } else {
              this.checked1 = false
            }
          }
          if(!this.form.shareImage){
            this.form.shareImage=''
          }
  
          if (!this.form.facilitatorResults) {
            this.form.facilitatorResults = [
              {
                id: null,
                informationName: null
              }
            ]
          }
          if (!this.form.productResults) {
            this.form.productResults = [
              {
                id: null,
                informationName: null
              }
            ]
          }
          if (!this.form.specialistResults) {
            this.form.specialistResults = [
              {
                id: null,
  
                specialistName: null
              }
            ]
          }
          if (!this.form.informationCompanies) {
            this.form.informationCompanies = [
              {
                id: null,
                companyFullName: null
              }
            ]
          }
          if (!this.form.informationProducts) {
            this.form.informationProducts = [
              {
                id: null,
                informationId: this.activeId,
                productFunctionDescribe: null,
                productImage: ''
              }
            ]
          }
          if (!this.form.informationProductValues) {
            this.form.informationProductValues = [
              {
                id: null,
                informationId: this.activeId,
                value: [],
                valueTitle: null
              }
            ]
          } else {
            this.form.informationProductValues.forEach((el) => {
              this.$set(el, 'input1', '')
              this.$set(el, 'input2', '')
              el.value = el.value.split(',')
              el.input1 = el.value[0]
              el.input2 = el.value[1]
            })
          }
  
          if (this.form.generalVersion == '0') {
            this.checked = true
          }
          
          // console.log(res.data.informationIntro.replace(/<[^>]+>|&[^>]+;/g, "").trim().replace('">', ""));
        }
        // console.log(this.form)
      },
      // 发送
      clickSend() {
        // console.log(this.form)
        // console.log('发送')
      },
      // 预览
      clickPreview() {
        // console.log('预览')
      },
      //视频上传
      uploadOneImgvideo(val) {
        this.form.videos = val
      },
      removeImgvideo(val) {
        this.form.videos = val
      },
      checkedchaneg() {
        if (this.checked1) {
          this.form.serviceStatus = 1
        } else {
          this.form.serviceStatus = 0
        }
      },
      // 发布机构人
  
      async querySearchAsyncapt(queryString, cb) {
        if (this.form.issueName) {
          const res = await getIssueName({
            name: this.form.issueName
          })
          if (res.data.resultCode == 200) {
            this.issueNameOption = res.data.data
            cb(this.issueNameOption)
            console.log(this.issueNameOption)
          }
        } else {
          cb(this.issueNameOption)
        }
      },
      //选择发布机构
      handleSelectcapt(item) {
        this.form.issueId = item.id
        this.form.issueName = item.issueName
        this.form.logo = item.issueLogo
      },
      async querySearchAsyncaptname(queryString, cb) {
        if (this.form.informationCompanies[0].companyFullName) {
          const res = await getCompanyList({
           id:this.activeId,
            type: this.form.informationType,
            queryParam: this.form.informationCompanies[0].companyFullName
          })
          if (res.data.resultCode == 200) {
            this.issueNameOptions = res.data.data
            cb(this.issueNameOptions)
          }
        } else {
          cb(this.issueNameOptions)
        }
      },
      //选择智选企业
      handleSelectcaptname(item) {
        this.form.companyId = item.id
        this.form.enterpriseIntro = item.enterpriseIntro
        this.form.informationCompanies[0].companyFullName = item.companyFullName
        this.form.informationCompanies[0].id = item.id
        
      },
      // 服务商资讯
  
      async querySearchAsyncapts(queryString, cb, index) {
        if (this.form.facilitatorResults[index].informationName) {
          const res = await getInformationAssociationVo({
            type: 4,
            queryParam: this.form.facilitatorResults[index].informationName
          })
          if (res.data.resultCode == 200) {
            this.specialist = res.data.data
            cb(this.specialist)
          }
        } else {
          cb(this.specialist)
        }
      },
  
      //选择服务商资讯
      handleSelectcapts(item, index) {
        this.form.facilitatorResults[index].id = item.id
        this.form.facilitatorResults[index].informationName = item.informationName
      },
      // 产品资讯
  
      async querySearchAsyncapts1(queryString, cb, index) {
        if (this.form.productResults[index].informationName) {
          const res = await getInformationAssociationVo({
            type: 5,
            queryParam: this.form.productResults[index].informationName
          })
          if (res.data.resultCode == 200) {
            this.facilist = res.data.data
            cb(this.facilist)
          }
        } else {
          cb(this.facilist)
        }
      },
  
      //选择产品资讯
      handleSelectcapts1(item, index) {
        this.form.productResults[index].id = item.id
        this.form.productResults[index].informationName = item.informationName
      },
      // 专家资讯
  
      async querySearchAsyncapts2(queryString, cb, index) {
        if (this.form.specialistResults[index].specialistName) {
          const res = await getSpecialistAssociationVo({
            queryParam: this.form.specialistResults[index].specialistName
          })
          if (res.data.resultCode == 200) {
            this.specilist = res.data.data
            cb(this.specilist)
          }
        } else {
          cb(this.specilist)
        }
      },
  
      //选择专家资讯
      handleSelectcapts2(item, index) {
        this.form.specialistResults[index].id = item.id
        this.form.specialistResults[index].specialistName = item.name
      },
      async querySearchAsyncapts3(queryString, cb, index) {
        if (this.form.informationCompanies[index].companyFullName) {
          const res = await getCompanyList({
            type: this.form.informationType,
            queryParam: this.form.informationCompanies[index].companyFullName
          })
          if (res.data.resultCode == 200) {
            this.specilist3 = res.data.data
            cb(this.specilist3)
          }
        } else {
          cb(this.specilist3)
        }
      },
  
      //选择企业
      handleSelectcapts3(item, index) {
        this.form.informationCompanies[index].id = item.id
        this.form.informationCompanies[index].companyFullName = item.companyFullName
      },
      //新增服务商
      addareas() {
        this.form.facilitatorResults.push({
          id: null,
          informationName: null
        })
      },
      //删除服务商
      delareas(index) {
        this.form.facilitatorResults.splice(index, 1)
      },
      //新增产品
      addareasprod() {
        this.form.productResults.push({
          id: null,
          informationName: null
        })
      },
      //删除产品
      delareasprod(index) {
        this.form.productResults.splice(index, 1)
      },
      //新增专家
      addareaspeci() {
        this.form.specialistResults.push({
          id: null,
          specialistName: null
        })
      },
      //删除专家
      delareaspeci(index) {
        this.form.specialistResults.splice(index, 1)
      },
      //新增企业
      addenterprise() {
        this.form.informationCompanies.push({
          id: null,
          companyFullName: null
        })
      },
      delenterprise(index) {
        this.form.informationCompanies.splice(index, 1)
      },
      //新增产品功能
      addDescribe() {
        this.form.informationProducts.push({
          id: null,
          informationId: this.activeId,
          productFunctionDescribe: null,
          productImage: ''
        })
      },
      delDescribe(index) {
        this.form.informationProducts.splice(index, 1)
      },
      //新增应用价值
      addvalueTitle() {
        this.form.informationProductValues.push({
          id: null,
          informationId: this.activeId,
          value: [],
          valueTitle: null
        })
      },
      delvalueTitle(index) {
        this.form.informationProductValues.splice(index, 1)
      },
      //上传pdf
      async beforeUpload(file) {
        this.file = file
        this.filename = file.name
  
        const extension = file.name.split('.').splice(-1) == 'pdf'
        if (!extension) {
          this.$message.warning('上传模板只能是pdf格式')
          return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
  
        let that = this
        reader.onload = function () {
          that.fileData = reader.result
        }
        return false
      },
      //上传
      async upload(params) {
        const form = new FormData()
        form.append('file', params.file)
  
        const res = await apiUpload(form)
        if (res.data.resultCode === 200) {
          this.form.companyFile = res.data.data
        }
      },
      //上传pdf
      async beforeUploadprod(file) {
        this.file = file
        this.filename = file.name
  
        const extension = file.name.split('.').splice(-1) == 'pdf'
        if (!extension) {
          this.$message.warning('上传模板只能是pdf格式')
          return
        }
        let reader = new FileReader()
        reader.readAsDataURL(file)
  
        let that = this
        reader.onload = function () {
          that.fileData = reader.result
        }
        return false
      },
      //上传
      async uploadprod(params, index) {
        const form = new FormData()
        form.append('file', params.file)
  
        const res = await apiUpload(form)
        if (res.data.resultCode === 200) {
          this.form.productFile = res.data.data
        }
      },
      removeImgDianprod(val, index) {
        this.form.informationProducts[index].productImage = ''
      },
      uploadOneImgShowDianprod(val, index) {
        this.form.informationProducts[index].productImage = val
      },
      clcikChecked() {
        if (this.checked == true) {
          this.form.generalVersion = '0'
        } else if (this.checked == false) {
          this.form.generalVersion = '1'
        }
        // console.log(this.checked)
      },
      //获取长地址图片
      uploadOneImgShowDians(val) {
        this.form.shareImage = val
      },
      /**
       * 删除图
       */
      removeImgDians(val) {
        this.form.shareImage = ''
      },
      dislodge() {
        if (!this.form.facilitatorResults[0].informationName) {
          this.form.facilitatorResults = null
        }
        if (!this.form.productResults[0].informationName) {
          this.form.productResults = null
        }
        if (!this.form.specialistResults[0].specialistName) {
          this.form.specialistResults = null
        }
        if (!this.form.informationCompanies[0].companyFullName) {
          this.form.informationCompanies = null
          this.form.companyId=''
        }
        if (!this.form.informationProducts[0].productFunctionDescribe) {
          this.form.informationProducts = null
        }
        if (this.form.informationProductValues[0].valueTitle == null) {
          this.form.informationProductValues = null
        } else {
          this.form.informationProductValues.forEach((el, index) => {
            el.value = []
            if (el.input1) {
              el.value.push(el.input1)
              delete el.input1
            }
            if (el.input2) {
              el.value.push(el.input2)
              delete el.input2
            }
            el.value = el.value.toString()
          })
        }
      },
  
      // 保存
      clickSave() {
        if (this.imageUrlList.length > 0) {
          this.form.imageList = this.imageUrlList
        }
        this.form.schemaName=this.parteninfo.schemaName
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.dislodge()
            if (this.form.id == null) {
              save(this.form).then((response) => {
                if (response.data.resultCode == 200) {
                  this.$message.success('新增成功')
                  this.$router.back()
                } else {
                  this.$message.error(response.data.message)
                }
              })
            } else {
             this.loading=true
              update(this.form).then((response) => {
                if (response.data.resultCode == 200) {
                  this.loading=false
                  this.$message.success('编辑成功')
                  this.$router.back()
                } else {
                  this.loading=false
                  this.$message.error(response.data.message)
                }
              })
            }
          }
        })
      },
      // 取消
      clickCancel() {
        this.$router.push({
          path: '/content'
        })
        // console.log('取消')
      },
      // 图片上传路径长地址  logo
      uploadOneImgShow(value) {
        // this.imageOneShow = value
        this.form.logo = value
      },
  
      //多个图片上传显示
      uploadOneImg(val) {
        this.imageUrlList = []
  
        val.forEach((item) => {
          this.imageUrlList.push(item.url)
        })
      },
      // 移除
      removeImg(val) {
        this.imageUrlList = []
        val.forEach((item) => {
          this.imageUrlList.push(item.url)
        })
      }
    },
  
    created() {
      if (this.activeId) {
        this.getSelectById()
        // this.imageOneShow = this.form.logo
      }
    },
  
    mounted() {}
  }
  </script>
  <style scoped lang="less">
  .number_top {
    width: 96px;
    height: 40px;
    background: #f4f4f4;
    border-radius: 2px;
    text-align: center;
    line-height: 40px;
    color: #bebebe;
    margin-bottom: 20px;
  }
  .formBox {
    background: #fff;
    padding-top: 24px;
    width: 100%;
    height: 100%;
    position: relative;
    .loading_po{
      position: absolute;
      bottom: 500px;
      width: 100%;
      z-index: 99999999!important;
    }
    .img_tips {
      font-size: 12px;
      color: #6ba1ff;
    }
    .h4_share {
      padding: 50px 50px 0px 50px;
      color: #146aff;
    }
  
    .inputBox {
      height: 40px;
      display: flex;
    }
    .general_inputs {
      width: 585px;
      border: 1px solid #d4d4d4;
      padding: 14px 20px;
      .input_flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 7px 0px;
        .valueType_flex{
          display: flex;
          align-items: center;
          padding-bottom: 10px;
        }
        span {
          padding-left: 15px;
          font-size: 20px;
          cursor: pointer;
        }
        .el_buttom {
          position: relative;
          left: -110px;
        }
      }
    }
    ::v-deep .textarea .el-textarea__inner {
      display: block;
      resize: vertical;
      padding: 5px 15px;
      line-height: 1.5;
      box-sizing: border-box;
      width: 480px;
      font-size: inherit;
      color: #606266;
      background-color: #fff;
      background-image: none;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    ::v-deep .percentage .el-input {
      width: 200px;
      height: 40px;
    }
  
    ::v-deep .textarea .el-textarea {
      position: relative;
      display: inline-block;
      width: 480px;
      vertical-align: bottom;
      font-size: 14px;
    }
    /deep/.el-input {
      width: 600px;
      height: 40px;
    }
  
    .labenBox {
      width: 120px;
      display: flex;
      justify-content: flex-end;
      margin-right: 12px;
  
      .label {
        color: #333333;
        font-size: 14px;
        margin-right: 2px;
      }
      .span {
        color: #f05252;
      }
    }
  
    .logo_box {
      display: flex;
      .label_box {
        display: flex;
        margin-right: 12px;
        width: 120px;
        justify-content: flex-end;
        .logo_label {
          color: #333333;
          font-size: 14px;
          margin-right: 2px;
        }
        .span {
          color: #f05252;
        }
      }
    }
  
    .cover {
      display: flex;
      flex-direction: column;
    }
  
    .picker {
      width: 240px !important;
    }
    /deep/.el-radio-group {
      display: flex;
      // flex-direction: column;
      align-items: center;
    }
    /deep/.el-radio__input {
      width: 18px;
      height: 18px;
    }
    /deep/.el-radio__inner {
      width: 18px;
      height: 18px;
    }
    /deep/.el-radio__label {
      font-size: 14px;
      color: #333333;
    }
  
    .editInformation {
      margin-top: 40px;
      margin-left: 58px;
      color: #999999;
      font-size: 14px;
  
      .item {
        margin-bottom: 6px;
      }
    }
    .button_box {
      width: 100%;
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .send {
        background-color: #f79646;
        color: #fff;
        cursor: pointer;
      }
      .preview {
        background-color: #37cc7e;
        color: #fff;
        cursor: pointer;
      }
      .save {
        background-color: #4e93fb;
        color: #fff;
        cursor: pointer;
      }
      .cancel {
        background-color: #fff;
        color: #4e93fb;
        cursor: pointer;
        border: 1px solid #4e93fb;
      }
      .button {
        width: 112px;
        height: 40px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 16px;
      }
    }
    .editBarBox {
      width: 100%;
      display: flex;
  
      .editbar_text {
        width: 130px;
        display: flex;
        justify-content: flex-end;
        .text_a {
          margin-right: 2px;
        }
        .span {
          margin-right: 12px;
          color: #f05252;
        }
      }
  
      .editBar {
        // width: 686px;
        // width: 100%;
        width: 375px;
        height: 487px;
      }
    }
  }
  .thumbnail {
    display: flex;
    color: #bebebe;
  }
  .thumbnail img {
    width: 143.23px;
    height: 81.63px;
    border-radius: 4px;
  }
  .thumbnail video {
    width: 160.28px;
    height: 91.35px;
    border-radius: 4px;
  }
  
  .thumbnail div {
    margin-right: 20px;
  }
  .thumbnail_box div {
    display: flex;
    flex-direction: column;
    height: 30px;
  }
  .number_loop {
    display: flex;
    align-items: flex-end;
  }
  // /deep/.w-e-menu {
  //   z-index: 2 !important;
  // }
  // /deep/.w-e-text-container {
  //   z-index: 1 !important;
  // }
  // /deep/.w-e-toolbar{
  //   z-index: 1 !important;
  // }
  
  ::v-deep .el-button--primary.is-plain {
    color: #448aff;
    background: #ecf3ff;
    border-color: #b4d0ff;
    border: none;
  }
  ::v-deep .name_form .el-input__inner {
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 550px;
  }
  
  ::v-deep .number_loop .el-input__inner {
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 180px;
  }
  ::v-deep .editor .text {
    border: 1px solid #ccc;
    min-height: 390px;
    max-height: 390px;
  }
  ::v-deep .w-e-text-container .w-e-text {
    max-height: 390px;
  }
  ::v-deep .txtree .el-textarea__inner {
    display: block;
    resize: vertical;
    padding: 5px 15px;
    line-height: 1.5;
    box-sizing: border-box;
    width: 600px;
    font-size: inherit;
    color: #606266;
    background-color: #fff;
    background-image: none;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  ::v-deep .el-textarea {
    position: relative;
    display: inline-block;
    width: 600px;
    vertical-align: bottom;
    font-size: 14px;
  }
  </style>
  